import * as React from "react"
import { ProductCard } from "./product-card"
import { CompareCard } from "./comparecard"

import { listingContainerStyle } from "./product-listing.module.css"
import styled from 'styled-components'
import { Swiper, SwiperSlide} from 'swiper/react';


const ItemContainer = styled.div`
`;


const CompareContainer = styled.div`
     height:100%;
`;

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function ProductListing({ products = [],  changeItem, readItem }) {


  return (
    <ItemContainer className="grid grid-cols-1 m-5 md:grid-cols-3 gap-4">
      {products.map((p, index) => (
        <ProductCard  product={p} key={p.id} eager={index === 0} />
      ))}
</ItemContainer>  )
}
